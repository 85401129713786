<template>
  <div class="status-info">
    <p class="mb-2">
      <b> {{ $t('test_self_diagnosis_title') }} </b>
      {{ $t('board_api_error_title') }}
    </p>

    <div class="flex flex-col justify-center items-center">

      <div class="status-info__block mb-2">
        <p class="status-info__text mb-1 text-center"> {{ $t('board_api_error_step_1') }}</p>
        <zoomable-image :src="require('../../../assets/images/TestStateGuide/Board/bots-list.png')" />
      </div>
      <a-divider />
      <div class="status-info__block mb-2">
        <p class="status-info__text mb-1 text-center"> {{ $t('board_api_error_step_2') }}</p>
        <zoomable-image :src="require('../../../assets/images/TestStateGuide/Board/bot-menu.png')" />
      </div>
      <a-divider />
      <div class="status-info__block mb-2">
        <p class="status-info__text mb-1 text-center"> {{ $t('board_api_error_step_3') }}</p>
        <zoomable-image :src="require('../../../assets/images/TestStateGuide/Board/ApiError/api1.png')" />
      </div>
      <a-divider />
      <div class="status-info__block mb-2">
        <p class="status-info__text mb-1 text-center"> {{ $t('board_api_error_step_4') }}</p>
        <zoomable-image :src="require('../../../assets/images/TestStateGuide/Board/ApiError/api2.png')" />
      </div>
      <a-divider />
      <div class="status-info__block mb-2">
        <p class="status-info__text mb-1 text-center"> {{ $t('board_api_error_step_5') }}</p>
        <zoomable-image :src="require('../../../assets/images/TestStateGuide/Board/ApiError/api3.png')" />
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Component from "vue-class-component";
import ZoomableImage from 'piramis-base-components/src/components/ZoomableImage/ZoomableImage.vue'

@Component({
  components: {
    ZoomableImage
  }
})
export default class BoardStatusApiError extends Vue {
}
</script>

<style scoped>

</style>